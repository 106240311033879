import { request, ResponseWithMeta, useRequest } from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const useGetBitrixManagers = () =>
  useRequest<ResponseWithMeta<Array<{ code: number; name: string }>>>(
    apiRoutesMap.bitrixManagers.getRoute(),
  );

export const createManager = (data: { code: number | string, name: string }) =>
  request.post(apiRoutesMap.bitrixManagers.getRoute(), data);

export const deleteBitrixManager = (id: number) =>
  request.delete(apiRoutesMap.bitrixManager.getRoute(id));

export const useGetBitrixRegionalManagers = () =>
  useRequest<ResponseWithMeta<Array<{ region: string; managers: number[] }>>>(
    apiRoutesMap.bitrixRegionalManagers.getRoute(),
  );

export const setRegionalManagers = (data: {
  region: string;
  managers: number[];
}) => request.patch(apiRoutesMap.bitrixRegionalManagers.getRoute(), data);

export const useGetBitrixResponsibleManager = () =>
  useRequest<ResponseWithMeta<{ code: number }>>(
    apiRoutesMap.bitrixResponsibleManager.getRoute(),
  );

export const setResponsibleManager = (data: { code: number }) =>
  request.patch(apiRoutesMap.bitrixResponsibleManager.getRoute(), data);
