import React from 'react';
import { Box } from '@mui/material';
import { Link } from '@mui/material';
import { Product } from 'src/types/api';

interface Props {
  product?: Product | null;
}

export const ProductPreview: React.FunctionComponent<Props> = ({ product }) => {
  if (!product) return null;

  const p = product;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {p?.defaultImage && (
        <Box
          sx={theme => ({
            marginRight: theme.spacing(2),
          })}
        >
          <a
            href={p?.defaultImage?.uri}
            target="_blank"
            style={{
              display: 'block',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '64px',
              height: '64px',
              backgroundColor: 'white',
              backgroundImage: `url(${p?.defaultImage?.uri}?w=50)`,
            }}
          ></a>
        </Box>
      )}
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          '&:not(:first-child) > div': {
            marginTop: theme.spacing(0.5),
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            '&>*': {
              marginRight: theme.spacing(1),
            },
            alignItems: 'center',
          })}
        >
          {p?.code && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products/${p?.code}`}
              target="_blank"
            >
              {p?.title}
            </Link>
          )}
        </Box>
        <Box
          sx={theme => ({
            fontSize: theme.typography?.pxToRem(11),
            opacity: 0.7,
          })}
        >
          [{p?.code}] {product.barcodes?.join(', ')}
        </Box>
        <Box
          sx={theme => ({
            display: 'flex',
            '&>*': {
              marginRight: theme.spacing(1),
            },
            opacity: 0.7,
            fontSize: theme.typography?.pxToRem(11),
            '&:hover': {
              opacity: 1,
            },
          })}
        >
          {p?.manufacturer?.id && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?manufacturers=${p?.manufacturer?.id}`}
              target="_blank"
              title={`Производитель ${p?.manufacturer?.name}`}
            >
              {p?.manufacturer?.name}
            </Link>
          )}
          {p?.brand?.id && (
            <Link
              href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?brands=${p?.brand?.id}`}
              target="_blank"
              title={`Бренд ${p?.brand?.name}`}
            >
              {p?.brand?.name}
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};
