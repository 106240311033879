import { Add, Delete, DeleteOutline, Remove } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import {
  createManager,
  deleteBitrixManager,
  setRegionalManagers,
  setResponsibleManager,
  useGetBitrixManagers,
  useGetBitrixRegionalManagers,
  useGetBitrixResponsibleManager,
} from 'src/entities/bitrix';
import { useRouter } from 'src/hooks/useRouter';
import { WithConfirm } from 'src/shared/ui';

function convertRegions(data: any) {
  const result: any = {};

  data?.forEach((item: any) => {
    const region = item.region;
    item.managers.forEach((managerId: any) => {
      if (!result[managerId]) {
        result[managerId] = [];
      }
      result[managerId].push(region);
    });
  });

  return result;
}

const AddManagerDialog = ({ onSave }: any) => {
  const [open, setOpen] = useState(false);

  const [code, setCode] = useState<null | string>(null);

  const [name, setName] = useState<null | string>(null);

  const handleCreate = () => {
    if (!code || !name) return null;
    createManager({ code, name }).then(() => {
      onSave();
      setOpen(false);
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color="primary" variant="contained">
        Добавить менеджера
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Добавить менеджера</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              label="Код"
              value={code}
              onChange={e => setCode(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label="Имя"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Отмена</Button>
          <Button
            disabled={!code || !name}
            onClick={handleCreate}
            color="primary"
            variant="contained"
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SelectRegion = ({ region, regions, onChange }: any) => {
  const router = useRouter();

  return (
    <FormControl>
      <InputLabel id="state-label">Регион</InputLabel>
      <Select
        sx={{ minWidth: 100 }}
        labelId="state-label"
        value={region}
        label="Регион"
        onChange={e => onChange(e.target.value)}
      >
        <MenuItem value="">Все</MenuItem>
        {regions?.map((item: any) => <MenuItem value={item}>{item}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

interface Props {}

export const Bitrix: React.FunctionComponent<Props> = ({}) => {
  const router = useRouter();

  const [selectedManager, setSelectedManager] = useState<any>(null);

  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  const managersRequest = useGetBitrixManagers();

  const regionalManagersRequest = useGetBitrixRegionalManagers();

  const responsibleManagerRequest = useGetBitrixResponsibleManager();

  const managersRegions = useMemo(
    () => convertRegions(regionalManagersRequest.data?.data),
    [regionalManagersRequest.data],
  );

  const regionsManagers = useMemo(
    () =>
      regionalManagersRequest.data?.data?.reduce(
        (prev, curr) => ({ ...prev, [curr.region]: curr.managers }),
        {},
      ),
    [regionalManagersRequest.data],
  );

  const refetch = () => {
    managersRequest.mutate();
    regionalManagersRequest.mutate();
    responsibleManagerRequest.mutate();
  };

  const handleDelete = (code: number, region: string) => {
    //@ts-ignore
    const managers = new Set(regionsManagers?.[region]);

    managers.delete(code);

    setRegionalManagers({
      region,
      //@ts-ignore
      managers: Array.from(managers),
    }).then(() => {
      refetch();
    });
  };

  const handleSetResponsible = (code: number) =>
    setResponsibleManager({ code }).then(() => {
      refetch();
    });

  const handleDeleteManager = (code: number) =>
    deleteBitrixManager(code).then(() => {
      refetch();
    });

  const handleAddManagerToRegion = () => {
    //@ts-ignore
    const managers = new Set(regionsManagers?.[selectedRegion]);

    managers.add(selectedManager.code);

    setRegionalManagers({
      region: selectedRegion,
      //@ts-ignore
      managers: Array.from(managers),
    }).then(() => {
      refetch();
      closeDialog();
    });
  };

  const closeDialog = () => {
    setSelectedManager(null);
    setSelectedRegion(null);
  };

  const emptyRegion = regionalManagersRequest.data?.data?.find(
    item => !item.managers?.length,
  );

  return (
    <div>
      {Boolean(selectedManager) && (
        <Dialog open={Boolean(selectedManager)} onClose={closeDialog}>
          <DialogTitle>{selectedManager?.name}: добавить регион</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <SelectRegion
                onChange={setSelectedRegion}
                region={selectedRegion}
                regions={regionalManagersRequest.data?.data?.map(
                  item => item.region,
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Отмена</Button>
            <Button
              onClick={handleAddManagerToRegion}
              color="primary"
              variant="contained"
            >
              Добавить
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper>
        <Box p={2}>
          <SelectRegion
            onChange={(value: any) =>
              router.push(null, value ? { region: value } : null)
            }
            region={router.query.region?.[0]}
            regions={regionalManagersRequest.data?.data?.map(
              item => item.region,
            )}
          />
        </Box>
      </Paper>
      {emptyRegion && (
        <Box mt={2}>
          <Alert severity="warning">
            Для региона {emptyRegion.region} не заданы менеджеры
          </Alert>
        </Box>
      )}
      <Box mt={2} maxWidth="500px">
        <Paper>
          <Box p={2}>
            <AddManagerDialog onSave={refetch} />
            <List>
              {managersRequest.data?.data
                ?.filter(item =>
                  router.query.region
                    ? //@ts-ignore
                      managersRegions?.[item.code]?.includes(
                        router.query.region?.[0],
                      )
                    : true,
                )
                .map(manager => (
                  <ListItem
                    divider
                    key={manager.code}
                    secondaryAction={
                      <Stack direction="row" spacing={1}>
                        <Checkbox
                          title="Назначить ответственным"
                          edge="end"
                          onChange={() => handleSetResponsible(manager.code)}
                          checked={
                            responsibleManagerRequest.data?.data?.code ===
                            manager.code
                          }
                        />
                        <WithConfirm
                          confirmTitle="Удалить"
                          onConfirm={() => handleDeleteManager(manager.code)}
                        >
                          <IconButton color="error" title="Удалить">
                            <DeleteOutline />
                          </IconButton>
                        </WithConfirm>
                      </Stack>
                    }
                    disablePadding
                  >
                    <ListItemIcon>{manager.code}</ListItemIcon>
                    <ListItemText
                      primary={manager.name}
                      secondary={
                        <React.Fragment>
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            alignItems="center"
                          >
                            {managersRegions?.[manager.code]?.map(
                              (item: any) => (
                                <Chip
                                  size="small"
                                  label={item}
                                  onDelete={() =>
                                    handleDelete(manager.code, item)
                                  }
                                />
                              ),
                            )}
                            <IconButton
                              title="Добавть регион"
                              size="small"
                              onClick={() => setSelectedManager(manager)}
                            >
                              <Add />
                            </IconButton>
                          </Stack>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Paper>{' '}
      </Box>
    </div>
  );
};
